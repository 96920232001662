<template>
  <v-container fluid fill-height class="d-flex justify-center align-center">
    <div class="d-flex flex-column justify-center align-center">
      <!-- <v-img src="@/assets/loading.gif" width="200px" contain class="mb-5" /> -->
      <v-progress-circular
        indeterminate
        color="primary"
        v-if="loading"
      ></v-progress-circular>
      <v-alert :type="type" v-else>{{ message }}</v-alert>
    </div>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("auth", {
      verifyEmail: "verifyEmail",
    }),
  },
  data() {
    return {
      loading: true,
      type: "success", //"error"
      message: "",
    };
  },
  mounted() {
    this.loading = true;
    this.verifyEmail({
      activation_token: this.$route.query.activation_token,
      _id: this.$route.query.id,
      type: this.$route.query.type,
    })
      .then((res) => {
        this.type = "success";
        this.message = res;
        this.loading = false;
      })
      .catch((error) => {
        this.type = "error";
        this.message =
          error.response && error.response.data
            ? error.response.data.message
            : error;
        this.loading = false;
      });
  },
};
</script>
<style scoped></style>
